<template>
  <app-container :title="'新增卡片'" :background="tool.getThemeList(theme)">
    <div class="bind_box">
      <div class="bind_card">
        <!-- 卡号 -->
        <div class="card_num">
          <span class="desc">卡号:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请填写卡号"
            v-model="card"
          />
        </div>
        <div class="card_mobile_underline"></div>
        <!-- 手机号码   验证码-->
        <div class="mobile_num">
          <span class="desc">手机号码:</span>
          <input
            class="mobile_input"
            type="text"
            style="border: none"
            placeholder="请输入手机号码"
            v-model="mobile"
            maxlength="11"
          />
          <button
            class="verification_code"
            :class="{
              verification_code:
                mobile && mobile.length == 11 && timeStyle && !timeStyleZero,
              verification_code_:
                (mobile &&
                  mobile.length == 11 &&
                  !timeStyle &&
                  !timeStyleZero) ||
                (mobile && mobile.length == 11 && !timeStyle && timeStyleZero)
            }"
            :disabled="time != '获取验证码'"
            @click="getSms"
          >
            {{ time }}
          </button>
        </div>
        <div class="card_code_underline"></div>
        <!-- 验证码 -->
        <div class="code_num">
          <span class="desc">验证码:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入验证码"
            v-model="code"
            maxlength="6"
          />
        </div>
        <div class="card_num_underline"></div>
        <!-- 昵称 -->
        <div class="real_name">
          <span class="desc">昵称:</span>
          <input
            class="real_name_input"
            type="text"
            style="border: none"
            placeholder="请输入卡片呢称，可不填写"
            v-model="nickname"
            maxlength="10"
          />
        </div>
        <div class="card_code_num_underline"></div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="submit_button" @click="submit">
      提交{{ actionList[pageType - 3] }}
    </div>
    <!-- 温馨提示 -->
    <div class="explain">
      <div class="explain_desc">温馨提示:</div>
      <div class="explain_text">1、请输入有效的卡片号码</div>
      <div class="explain_text">2、如果卡片号码停用将会导致绑定失败</div>
    </div>
  </app-container>
</template>

<script>
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { setStore } from "@/utils/store";
import { useRoute } from "vue-router";
import {
  bindMobile,
  getMobileCode,
  getMobileIsRealName
} from "_API_/api.services";
import { isvalidatemobile } from "@/utils/validate";
export default {
  setup() {
    const state = reactive({
      theme: "", //主题名称
      card: "", //卡号
      cardId: "", //卡ID
      code: "", //验证码
      mobile: "", //手机号码
      nickname: "", //卡片昵称
      type: 3, //类型
      testType: 1,
      pageType: "", //绑定类型
      openIdcard: "", //openIdcard
      time: "获取验证码",
      timeId: "", //验证码定时器Id
      timeStyle: false, //验证码样式控制
      timeStyleZero: false, //验证码倒计时为0的样式控制
      actionList: ["绑定", "解绑"]
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //获取验证码
      getSms() {
        //检测手机号
        if (!Boolean(state.mobile) || state.mobile.length < 11) {
          tool.alert({
            title: "提示",
            msg: "请输入正确的手机号"
          });
          return;
        }
        //验证手机号
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            msg: result_phone[1]
          });
          return;
        }
        //检测手机号码是否已经绑定过
        getMobileIsRealName({
          card: state.card,
          mobile: state.mobile
        }).then(({ data }) => {
          if (data.code == 0) {
            state.openIdcard = data.realName;
            return data;
          }
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        });
        //验证码接口
        getMobileCode({
          type: state.type,
          card: state.card,
          mobile: state.mobile,
          cardId: state.cardId
        }).then(({ data }) => {
          if (data.code == 0) {
            ctx.timeBack(60);
            tool.toast({
              msg: "发送成功",
              duration: 1500
            });
            return;
          }
          tool.toast({
            msg: data.msg,
            duration: 1500
          });
        });
      },
      //验证码回调
      timeBack(time) {
        if (time <= 0) {
          clearTimeout(state.timeId);
          return;
        }
        state.time = time--;
        state.timeStyle = true;
        state.time = time + "s";
        if (state.time === "0s") {
          state.timeStyleZero = true;
          state.timeStyle = false;
          state.time = "获取验证码";
        }
        state.timeId = setTimeout(() => {
          ctx.timeBack(time);
        }, 1000);
      },
      //提交绑定新的卡片
      submit() {
        if (state.pageType == 3) {
          ctx.bindCard();
        }
      },
      //绑定卡片
      bindCard() {
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            // msg: result_phone[1],
            msg: "请输入正确的手机号并获取验证码"
          });
          return;
        }
        //验证码校验
        if (!Boolean(state.code)) {
          tool.toast(
            {
              msg: "请输入验证码" + state.code,
              duration: 1500
            },
            () => {}
          );
          return;
        }
        // 添加别称非必填，数字+字母+中文。最多10位
        if (
          state.nickname &&
          !/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(state.nickname)
        ) {
          tool.toast(
            {
              msg: "请输入正确的卡片别称",
              duration: 1500
            },
            () => {}
          );
          return;
        }
        //调用绑定手机号码接口
        bindMobile({
          card: state.card,
          cardId: state.cardId,
          code: state.code,
          mobile: state.mobile,
          nickname: state.nickname,
          testType: 1,
          IDCard: "",
          realName: "",
          cardPic1: "",
          cardPic2: "",
          exchangeCode: ""
        }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "绑定成功",
                duration: 1500
              },
              () => {
                //存储绑定标识到sessionStorage
                setStore({
                  name: "userBind",
                  content: {
                    isShow: "2",
                    nickname: state.nickname,
                    mobile: state.mobile
                  },
                  type: "session"
                });
                //返回个人中心
                router.push({
                  name: "personal"
                });
              }
            );
            return;
          }
          if (res.code == 222) {
            tool.toast(
              {
                msg: "身份认证失败，请上传身份证照提交绑定",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => {}
          );
        });
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      Object.assign(state, {
        cardId: info.cardId || "", //获取卡号Id
        mobile: info.mobile || "" //获取绑定的手机号码
      });
      // 接收路由携带参数
      state.pageType = route.query.pageType || 3;
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.bind_box {
  width: 690px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  padding-top: 20px;
  .bind_card {
    width: 630px;
    height: 408px;
    margin: 0 auto;
    .card_num,
    .code_num,
    .real_name {
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .real_name_input {
        width: 430px;
        margin-left: 40px;
      }
    }
    .card_num,
    .code_num {
      width: 600px;
      justify-content: space-between;
      input {
        width: 430px;
        margin-right: 60px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .code_num {
      input {
        width: 430px;
        margin-right: 50px;
      }
    }
    .code_num {
      width: 600px;
      justify-content: space-between;
    }
    .real_name {
      width: 630px;
      margin-left: 20px;
      input {
        width: 430px;
        margin-left: 100px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .card_mobile_underline,
    .card_num_underline,
    .card_code_underline,
    .card_code_num_underline {
      height: 1px;
      background: #f2f2f2;
    }
    .card_mobile_underline {
      width: 490px;
      margin: 0 130px;
    }
    .card_num_underline {
      width: 480px;
      margin: 0 140px;
    }
    .card_code_underline {
      width: 426px;
      margin: 0 190px;
    }
    .card_code_num_underline {
      width: 500px;
      margin: 0 126px;
    }
    .mobile_num {
      width: 600px;
      height: 88px;
      justify-content: space-between;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }

      .mobile_input {
        width: 214px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .verification_code {
        width: 170px;
        height: 50px;
        font: {
          size: 22px;
          weight: 400;
        }
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        background: #aaaaaa;
        border-radius: 10px;
        border: none;
      }
      .verification_code_ {
        width: 170px;
        height: 50px;
        font: {
          size: 22px;
          weight: 400;
        }
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        @include background_color("background_color1");
        border-radius: 10px;
        border: none;
      }
    }
  }
}

.explain {
  width: 690px;
  height: 188px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .explain_desc {
    font: {
      size: 30px;
      weight: 400;
    }
    color: #333333;
  }
  .explain_text {
    width: 690px;
    font: {
      size: 22px;
      weight: 400;
    }
    color: #777777;
  }
}
.submit_button {
  width: 690px;
  height: 84px;
  color: #ffffff;
  @include background_color("background_color1");
  box-shadow: 0px 8px 16px 0px #bce1ff;
  border-radius: 20px;
  margin: 20px auto 20px;
  text-align: center;
  line-height: 84px;
}
</style>
